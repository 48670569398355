import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import media from '../styles/media'

import './layout.css'
import Header from "./header"

const LayoutCon = styled.div`
  margin: 20px 20px;

  ${media.laptop`
    margin: 20px 60px;
  `}
`

const Layout = ({ children }) => {

  return (
    <LayoutCon>
      <Header siteTitle='It&amp;s Home' />
      <div>
        <main>{children}</main>
      </div>
    </LayoutCon>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
