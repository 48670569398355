import React from "react"
import styled from 'styled-components'
import media from '../styles/media'
import * as Scroll from "react-scroll"

import Logo from '../images/logo.png'

const HeaderCon = styled.header`
  height: 10vh;
  display: flex;
  justify-content: space-between;
  .home {
    cursor: pointer;
    img {
      width: 120px;
      margin: 0px 0;
    }
  }
  .menu-items {
    display: flex;
    margin: 0;
    a {
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      :nth-child(1) {
        margin-right: 20px;
      }
    }
  }

  ${media.laptop`
    .home {
      img {
        width: 180px;
        margin: 10px 0;
      }
    }
    .menu-items {
      display: flex;
      margin: 10px 0 0;
      a {
        font-size: 18px;
        margin-right: 50px;
        :nth-child(1) {
          margin-right: 80px;
        }
        transition: 300ms;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  `}
`

const Header = () => {
  const Link = Scroll.Link;

  return (
    <HeaderCon>
      <Link to="home" spy={true} smooth={true} offset={-50} duration={700} className="home">
        <img src={Logo} alt="It's Home" />
      </Link>
      <div className="menu-items">
        <Link to="about" spy={true} smooth={true} offset={-50} duration={700}>About</Link>
        <Link to="contact" spy={true} smooth={true} offset={-50} duration={700}>Contact</Link>
      </div>
    </HeaderCon>
  )
}

export default Header
